<template>
    <section v-if="group && group.products.length > 0" :class="componentThemeClass">
        <Container class="py-16 lg:py-32">
            <div class="flex justify-between items-center mb-8">
                <h2 class="mb-0" data-aos="fade-right">{{ group.title }}</h2>
                <a :href="$router.resolve({name: 'collection', params: { slug: group.slug }}).href" :title="group.title"
                   :text-colour="_textColour" class="text-xl uppercase font-semibol" data-aos="fade-left">View collection</a>
            </div>
            <div class="grid grid-flow-col auto-cols-1/2 scroll-bar pb-8 gap-4 lg:gap-8 lg:auto-cols-1/4">
                <div v-for="(product, index) in group.products" :key="index" data-aos="fade-in" :data-aos-delay="index * 50">
                    <Product :product="product" />
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    import Product from '@/components/elements/Product.vue'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'ProductGroupSection',
        inject: ['sdk', 'errorHandler'],
        mixins: [ComponentTheme],
        components: {
            Product,
            Container
        },
        props: {
            productGroupSlug: String,
            productGroup: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                group: null
            }
        },
        methods: {
            loadProductGroup: function () {
                this.sdk.getProductGroupBySlug(this.productGroupSlug)
                    .then(response => {
                        this.group = response.product_groups[0]
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
        },
        created() {
            if (this.productGroup) {
                this.group = this.productGroup
            } else {
                this.loadProductGroup()
            }
        }
    }
</script>
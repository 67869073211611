<template>
    <div data-aos="fade-in" data-aos-duration="300" style="transform: initial">
        <GlobalAlert />
        <Navigation />
        <slot></slot>
        <Footer/>
    </div>
</template>

<script>
    import GlobalAlert from '@/components/GlobalAlert.vue'
    import Navigation from '@/components/Navigation.vue'
    import Footer from '@/components/Footer.vue'
    import SiteContent from '@/mixins/siteContent'

    export default {
        name: 'DefaultLayout',
        inject: ['sdk', 'dataStore'],
        mixins: [SiteContent],
        components: {
            GlobalAlert,
            Navigation,
            Footer
        },
        computed: {
            primaryNavigation: function () {
                return this.getSiteContentByKey('primary_navigation')
            }
        }
    }
</script>
<template>
    <RelatedProductSection v-if="products.length > 0" :title="title" :link-text="linkText" :link-url="linkUrl" :products="products" :background-colour="_backgroundColour"
                           :text-colour="_textColour"/>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import RelatedProductSection from '@/components/sections/RelatedProductSection.vue'

    export default {
        name: 'RecentlyViewedProductSection',
        inject: ['sdk', 'dataStore','errorHandler'],
        mixins: [ComponentTheme],
        components: {RelatedProductSection},
        props: {
            title: String,
            linkUrl: String,
            linkText: String,
        },
        data() {
            return {
                products: []
            }
        },
        methods: {
            loadProducts: function () {
                if(this.dataStore.getRecentlyViewed().length > 0) {
                    const filters = [
                        {
                            property: 'id',
                            value: this.dataStore.getRecentlyViewed(),
                            operator: 'whereIn'
                        },
                        {
                            property: 'inventory',
                            value: 1,
                            operator: '>='
                        }
                    ]

                    this.sdk.getProducts({page: 1}, filters, [], ['childProducts'])
                        .then(response => {
                            this.products = response.products
                        }).catch(error => {
                        this.errorHandler.handleError(error);
                    })
                }
            }
        },
        created() {
            this.loadProducts()
        }
    }
</script>
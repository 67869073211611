<template>
    <nav v-if="store" class="w-full sticky top-0 z-20 drop-shadow-md bg-white text-black" @mouseleave="activeNav = null">
        <Container class="pt-4 pb-4">
            <div class="flex items-center justify-between">
                <div>
                    <router-link :to="{name: 'home'}" title="Home">
                        <img src="/logo.svg" :alt="'Image: ' + store.name + ' Logo'" class="max-h-[50px] lg:max-h-[70px]" loading="lazy" />
                    </router-link>
                </div>
                <div class="hidden px-4 lg:block">
                    <ul class="flex items-center font-heading text-2xl" :class="textColourClass">
                        <li v-for="category in categories" :key="category.id" class="mr-10">
                            <a :title="category.title" :href="$router.resolve({name: 'category', params: { slug: category.slug}}).href" @mouseover="activeNav = category.slug">{{ category.title }}</a>
                        </li>
                        <template v-if="primaryNavigation">
                            <li v-for="(link, index) in primaryNavigation.value.links" :key="index" class="mr-10">
                                <a :href="link.url" :title="link.title">{{ link.title }}</a>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="ml-auto lg:ml-0">
                    <ul class="flex items-center">
                        <li class="hidden lg:block lg:ml-6">
                            <button :title="'Search'" @click.prevent="searchToggle"><MagnifyingGlassIcon  class="inline h-6 w-6" /></button>
                        </li>
                        <li class="hidden ml-4 items-center text-xs lg:flex">
                            <span>Ex. VAT</span>
                            <span class="mx-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
                                    :class="[includeTax ? 'bg-green' : 'bg-primary']"
                                    @click.prevent="includeTaxToggle"
                                    role="switch" aria-checked="false"
                            >
                                <span aria-hidden="true"
                                      class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      :class="[includeTax ? 'translate-x-5' : 'translate-x-0']"
                                >
                                </span>
                            </span>
                            <span>Inc. VAT</span>
                        </li>
                        <li class="ml-4 lg:ml-6">
                            <router-link :to="{ name: 'basket'}" title="Basket">
                                <ShoppingBagIcon class="inline h-6 w-6" />({{ basketItemTotal }})
                            </router-link>
                        </li>
                        <li v-if="!dataStore.loggedIn()" class="hidden ml-4 lg:ml-6  lg:block">
                            <router-link :to="{ name: 'login'}" title="Login"><UserIcon class="inline h-6 w-6" /></router-link>
                        </li>
                        <li v-if="dataStore.loggedIn()" class="hidden ml-4 lg:ml-6  lg:block">
                            <router-link :to="{ name: 'account'}" title="Account"><UserIcon class="inline h-6 w-6" /></router-link>
                        </li>
                        <li v-if="dataStore.loggedIn()" class="ml-4 lg:ml-6">
                            <button @click="logout()"><ArrowLeftOnRectangleIcon class="inline h-6 w-6" /></button>
                        </li>
                    </ul>
                </div>
                <transition name="fade" mode="out-in">
                    <div v-if="menuOpen" class="z-50 top-0 left-0 fixed block h-screen w-screen bg-white lg:hidden overflow-hidden">
                        <Container class="pt-4 pb-4 h-full">
                            <div class="flex items-center justify-between mb-6">
                                <div class="text-black">
                                    <router-link :to="{name: 'home'}" title="Home">
                                        <img src="/logo.svg" :alt="'Image: ' + store.name + ' Logo'" class="max-h-[50px] lg:max-h-[75px]" loading="lazy" />
                                    </router-link>
                                </div>
                                <button @click.prevent="menuToggle">
                                    <XMarkIcon class="inline h-8 w-8" />
                                </button>
                            </div>
                            <div class="overflow-scroll max-h-[85%]">
                                <ul class="font-heading text-3xl text-black">
                                    <li v-for="category in categories" :key="category.id" class="mb-2">
                                        <a :title="category.title"  @click.prevent="activeNav === category.slug ? activeNav = null : activeNav = category.slug">{{ category.title }}</a>
                                        <ChevronUpIcon v-if="activeNav === category.slug" class="inline ml-2 h-8 w-8"/>
                                        <ChevronDownIcon v-else class="inline ml-2 h-8 w-8"/>
                                        <ul class="my-4 font-sans font-normal normal-case leading-tight text-lg columns-2" :class="[activeNav === category.slug ? 'lg:block' : 'hidden']">
                                            <li class="mb-3">
                                                <a :href="$router.resolve({name: 'category', params: { slug: category.slug}}).href">
                                                    All {{ category.name }}s
                                                </a>
                                            </li>
                                            <li v-for="option in category.category_options" class="mb-3">
                                                <a :href="$router.resolve({name: 'product-categories', params: { category: category.slug, option: option.value}}).href">
                                                    {{ option.name }}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <template v-if="primaryNavigation">
                                        <li v-for="(link, index) in primaryNavigation.value.links" :key="index" class="mb-2">
                                            <a :href="link.url" :title="link.title">{{ link.title }}</a>
                                        </li>
                                    </template>
                                    <li class="mb-2">
                                        <router-link :to="{ name: 'search'}" title="Search">Search</router-link>
                                    </li>
                                    <li class="mb-2">
                                        <router-link :to="{ name: 'basket'}" title="Basket">Basket ({{ basketItemTotal }})</router-link>
                                    </li>
                                    <li v-if="!dataStore.loggedIn()">
                                        <router-link :to="{ name: 'login'}" title="Login">Login</router-link>
                                    </li>
                                    <li v-if="dataStore.loggedIn()" class="mb-2">
                                        <router-link :to="{ name: 'account'}" title="Account">Account</router-link>
                                    </li>
                                    <li v-if="dataStore.loggedIn()">
                                        <button @click="logout()">Logout</button>
                                    </li>
                                </ul>
                            </div>
                        </Container>
                    </div>
                </transition>
                <div class="block ml-4 lg:hidden">
                    <a @click.prevent="menuToggle">
                        <Bars3Icon class="inline h-8 w-8" :class="textColourClass" />
                    </a>
                </div>
            </div>
            <div class="flex items-center justify-center text-xs mt-4 pt-4 border-t lg:hidden">
                <span>Ex. VAT</span>
                <span
                        class="mx-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
                        :class="[includeTax ? 'bg-green' : 'bg-primary']"
                        @click.prevent="includeTaxToggle"
                        role="switch" aria-checked="false"
                >
                                <span aria-hidden="true"
                                      class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      :class="[includeTax ? 'translate-x-5' : 'translate-x-0']"
                                ></span>
                </span>
                <span>Inc. VAT</span>
            </div>
            <BasketAlert class="hidden lg:block" />
        </Container>
        <div v-if="searchOpen" class="hidden lg:block">
            <Container class="py-16 lg:max-w-4xl">
                <form :action="searchFormUrl" class="flex items-center border-b-2" :class="' border-' + _textColour">
                    <button><MagnifyingGlassIcon class="inline h-8 w-8" /></button>
                    <input type="text" name="query" class="border-0 bg-transparent text-2xl w-full" placeholder="What are you looking for?" />
                    <a @click.prevent="searchToggle"><XMarkIcon class="inline h-8 w-8" /></a>
                </form>
            </Container>
        </div>
        <template v-for="category in categories" :key="category.id">
            <div :id="category.slug" class="bg-white w-full hover:block hidden border-t" :class="[activeNav === category.slug ? 'lg:block' : '']">
                <Container class="py-6">
                    <ul class="font-sans normal-case leading-tight columns-5">
                        <li v-for="option in category.category_options" class="mb-3">
                            <a :href="$router.resolve({name: 'product-categories', params: { category: category.slug, option: option.value}}).href">{{ option.name }}</a>
                        </li>
                    </ul>
                </Container>
            </div>
        </template>
    </nav>
    <div class="bg-primary border border-b-white">
        <Container class="py-4">
            <div class="flex justify-center lg:justify-items-center lg:gap-16">
                <div class="flex w-1/2 items-center lg:w-auto lg:max-w-[400px]">
                    <TruckIcon class="inline h-7 w-7 mr-2" />
                    <a :href="$router.resolve({name: 'page', params: { slug: 'free-delivery'}}).href" title="Free delivery">
                        <p class="mb-0 font-semibold uppercase leading-tight">Free delivery</p>
                        <p class="mb-0 text-small">Free delivery on all orders over £99</p>
                    </a>
                </div>
                <div class="flex w-1/2 items-center lg:w-auto lg:max-w-[400px]">
                    <TagIcon class="inline h-7 w-7 mr-2" />
                    <a :href="$router.resolve({name: 'page', params: { slug: 'big-savings'}}).href" title="Free delivery">
                        <p class="mb-0 font-semibold uppercase leading-tight">Big savings available</p>
                        <p class="mb-0 text-small">Reduced pricing on bulk purchases</p>
                    </a>
                </div>
            </div>
        </Container>
    </div>
    <BasketAlert class="block lg:hidden" />
</template>

<script>
    import { Bars3Icon, XMarkIcon, ShoppingBagIcon, UserIcon, ArrowLeftOnRectangleIcon, MagnifyingGlassIcon, TruckIcon, TagIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'
    import Container from '@/components/Container.vue'
    import BasketAlert from '@/components/BasketAlert.vue'
    import SiteContent from '@/mixins/siteContent'
    import BasketActions from '@/mixins/basketActions'
    import StoreContent from '@/mixins/storeContent'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'Navigation',
        inject: ['dataStore', 'sdk'],
        mixins: [ComponentTheme, StoreContent, SiteContent, BasketActions],
        components: {
            Container,
            BasketAlert,
            Bars3Icon,
            XMarkIcon,
            ShoppingBagIcon,
            UserIcon,
            ArrowLeftOnRectangleIcon,
            MagnifyingGlassIcon,
            TruckIcon,
            TagIcon,
            ChevronDownIcon,
            ChevronUpIcon
        },
        props: {
            position: String
        },
        data() {
            return {
                menuOpen: false,
                activeNav: null,
                searchOpen: false,
                categories: null,
            }
        },
        computed: {
            basket: function () {
                return this.dataStore.state.basket
            },
            primaryNavigation: function () {
                return this.getSiteContentByKey('primary_navigation')
            },
            searchFormUrl: function () {
                return this.$router.resolve({name: 'search'}).href
            },
            includeTax: function(){
                return this.dataStore.getIncludeTax()
            },
        },
        methods: {
            menuToggle: function () {
                this.menuOpen = !this.menuOpen
            },
            searchToggle: function () {
                this.searchOpen = !this.searchOpen
            },
            logout: function(){
                this.dataStore.logout()
                this.$router.push({name: 'login'});
            },
            includeTaxToggle: function(){
                this.dataStore.setIncludeTax(!this.dataStore.getIncludeTax())
                location.reload();
            },
            async loadCategories () {
                const filters = [{
                    property: 'slug',
                    operator: 'whereIn',
                    value: ['shop', 'brand', 'type']
                }]
                return this.sdk.getCategories({page: 1}, filters)
                    .then(response => {
                        this.categories = response.categories;
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                    })
            },
            async loadBasket () {
                const basketReference = this.dataStore.getBasketReference();

                if(basketReference){
                    return this.sdk.getBasketById(basketReference)
                        .then(response => {
                            const basket = response.basket;
                            this.dataStore.setBasket(basket);
                        }).catch(error => {
                            this.errorHandler.handleError(error);
                        })
                }
            },
        },
        created() {
            this.loadCategories()
            this.loadBasket()
        }
    }
</script>
<template>
    <section :class="componentThemeClass">
        <Container class="py-16 lg:py-32">
            <div class="text-center max-w-4xl mx-auto">
                <p class="h1 font-heading whitespace-pre-line" data-aos="fade-right">{{ text }}</p>
            </div>
            <div v-if="links && links.length > 0" class="grid gap-2 mt-12 lg:grid-flow-col">
                <LinkButton v-for="(link, index) in links" :key="index" :link="link.url" :text-colour="_textColour" :background-colour="_backgroundColour" data-aos="fade-up" :data-aos-delay="index * 50">
                    {{ link.title }}
                </LinkButton>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    import ComponentTheme from '@/mixins/componentTheme'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'IntroTextSection',
        mixins: [ComponentTheme],
        components: {Container, LinkButton},
        props: {
            text: String,
            links: Array
        }
    }
</script>
<template>
    <div class="border p-3 mb-3" role="alert" :class="themeStyle">
        <span v-for="(message, index) in messages" :key="index" class="block">{{message}}</span>
    </div>
</template>

<script>
    export default {
        name: 'Alert',
        props: {
            messages: Array,
            theme: {
                type: String,
                default: 'error'
            }
        },
        computed: {
            themeStyle: function () {
                if(this.theme === 'success') {
                    return 'bg-green-100 border-green-400 text-green-700'
                }

                return 'bg-red-100 border-red-400 text-red-700'
            }
        }
    }
</script>




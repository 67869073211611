<template>
    <div class="overflow-hidden">
        <template v-for="(content, index) in pageContent.content" :key="index">
            <template v-if="!content.content_type">
                <HtmlSection :content="content.value"/>
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'accordion_section'">
                <AccordionSection
                    :background-colour="content.value.background_colour"
                    :text-colour="content.value.text_colour"
                    :title="content.value.title"
                    :items="content.value.items"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'related_product_section'">
                <RelatedProductSection
                    :background-colour="content.value.background_colour"
                    :text-colour="content.value.text_colour"
                    :title="content.value.title"
                    :link-url="content.value.link_url"
                    :link-text="content.value.link_text"
                    :products="content.value.products"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'tagged_product_section'">
                <TaggedProductSection
                    :background-colour="content.value.background_colour"
                    :text-colour="content.value.text_colour"
                    :title="content.value.title"
                    :link-url="content.value.link_url"
                    :link-text="content.value.link_text"
                    :tag-slug="content.value.tag"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'product_group_section'">
                <ProductGroupSection
                    :background-colour="content.value.background_colour"
                    :text-colour="content.value.text_colour"
                    :product-group-slug="content.value.group"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'content_panel_section'">
                <ContentPanelSection
                    :panels="content.value.panels"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'image_banner_section'">
                <ImageBannerSection
                    :background-colour="content.value.background_colour"
                    :text-colour="content.value.text_colour"
                    :title="content.value.title"
                    :text="content.value.text"
                    :link-url="content.value.link_url"
                    :link-text="content.value.link_text"
                    :image="content.value.image.url"
                    :text-style="content.value.text_style"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'image_grid_section'">
                <ImageGridSection
                    :background-colour="content.value.background_colour"
                    :text-colour="content.value.text_colour"
                    :title="content.value.title"
                    :gap-size="content.value.gap_size"
                    :items-per-row="content.value.items_per_row"
                    :enable-gallery="content.value.enable_gallery"
                    :panels="content.value.panels"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'content_grid_section'">
                <ContentGridSection
                    :background-colour="content.value.background_colour"
                    :text-colour="content.value.text_colour"
                    :title="content.value.title"
                    :link-url="content.value.link_url"
                    :link-text="content.value.link_text"
                    :panels="content.value.panels"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'intro_text_section'">
                <IntroTextSection
                    :background-colour="content.value.background_colour"
                    :text-colour="content.value.text_colour"
                    :text="content.value.text"
                    :links="content.value.links"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'contact_section'">
                <ContactSection
                    :background-colour="content.value.background_colour"
                    :text-colour="content.value.text_colour"
                    :content="content.value.content"
                />
            </template>
            <template v-else-if="content.content_type && content.content_type.key === 'brand_section'">
                <BrandSection
                        :background-colour="content.value.background_colour"
                        :text-colour="content.value.text_colour"
                        :title="content.value.title"
                        :brand-images="content.value.brand_images"
                />
            </template>
        </template>
    </div>
</template>

<script>
    import AccordionSection from '@/components/sections/AccordionSection.vue'
    import RelatedProductSection from '@/components/sections/RelatedProductSection.vue'
    import TaggedProductSection from '@/components/sections/TaggedProductSection.vue'
    import ProductGroupSection from '@/components/sections/ProductGroupSection.vue'
    import ContentPanelSection from '@/components/sections/ContentPanelSection.vue'
    import ImageBannerSection from '@/components/sections/ImageBannerSection.vue'
    import ImageGridSection from '@/components/sections/ImageGridSection.vue'
    import IntroTextSection from '@/components/sections/IntroTextSection.vue'
    import ContentGridSection from '@/components/sections/ContentGridSection.vue'
    import ContactSection from '@/components/sections/ContactSection.vue'
    import HtmlSection from '@/components/sections/HtmlSection.vue'
    import BrandSection from '@/components/sections/BrandSection.vue'

    export default {
        name: 'ContentSections',
        components: {
            AccordionSection,
            RelatedProductSection,
            TaggedProductSection,
            ProductGroupSection,
            ContentPanelSection,
            ImageBannerSection,
            IntroTextSection,
            ImageGridSection,
            ContentGridSection,
            ContactSection,
            HtmlSection,
            BrandSection
        },
        props: {
            pageContent: Object,
        }
    }
</script>




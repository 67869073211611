<template>
    <a :href="link" class="uppercase font-semibold transition-all text-center py-3 px-6 border disabled:opacity-50 disabled:pointer-events-none" :class="buttonClass">
        <slot></slot>
    </a>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'LinkButton',
        mixins: [ComponentTheme],
        props: {
            link: String,
            customClass: String,
            rounded: Boolean
        },
        computed: {
            buttonClass: function(){
                let cssClass = [this.componentThemeClass];

                if(this._backgroundColour === 'transparent'){
                    cssClass.push('border-' + this._textColour)
                    cssClass.push('hover:bg-' + this._textColour)
                }
                else{
                    cssClass.push('border-' + this._backgroundColour)
                    cssClass.push('hover:bg-transparent')
                }

                if(this._textColour === 'white'){
                    cssClass.push('hover:text-black')
                }

                if(this._textColour === 'black'){
                    cssClass.push('hover:text-white')
                }

                return cssClass.join(' ');
            }
        }
    }
</script>




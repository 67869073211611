<template>
    <Head v-if="loaded">
        <title>{{ siteTitle }} | {{ pageTitle }}</title>
        <meta name="description" :content="pageDescription">
        <link rel="canonical" :href="canonical" />
    </Head>
    <template v-if="loaded">
        <router-view/>
    </template>
</template>

<script>
    import PageMeta from '@/mixins/pageMeta'
    import dataStore from '@/store/data'
    import AOS from 'aos'
    import axios from 'axios'
    import {Head} from '@vueuse/head'

    export default {
        name: 'App',
        inject: ['dataStore'],
        mixins: [PageMeta],
        props: {
            sdk: null,
            errorHandler: null
        },
        components: {Head},
        computed: {
            canonical: function () {
                return this.dataStore.getSite().url + this.$router.currentRoute.value.path
            }
        },
        data() {
            return {
                loaded: false
            }
        },
        methods: {
            async generateToken(){
                return this.sdk.generateToken()
                    .then(response => {
                        const token = response.token;
                        this.dataStore.setApiToken(token);
                        this.loadComponentData().finally(() => {
                            this.loaded = true
                        })
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                    })
            },
            async loadToken() {
                const apiToken = this.dataStore.getApiToken()

                if (apiToken) {
                    return this.sdk.getTokenByToken(apiToken)
                        .then(response => {
                            const token = response.token;
                            this.dataStore.setApiToken(token);
                            this.loadComponentData().finally(() => {
                                this.loaded = true
                            })
                        }).catch(error => {
                            if (error.response.status === 404) {
                                this.generateToken();
                            }
                            this.errorHandler.handleError(error);
                        })
                }

                return this.generateToken()
            },
            async loadStore () {
                this.sdk.getStore(['features']).then(response => {
                    const store = response.store
                    this.dataStore.setStore(store);
                }).catch(error => {
                    this.errorHandler.handleError(error);
                });
            },
            async loadSite () {
                return this.sdk.getChannelBySlug('website').then(response => {
                    const site = response.channels[0]
                    // Convert all the content to objects for ease
                    site.content.forEach(content => {
                        content.value = JSON.parse(content.value)
                    })
                    this.dataStore.setSite(site)
                }).catch(error => {
                    this.errorHandler.handleError(error);
                });
            },
            async loadComponentData(){
                return await axios.all([this.loadStore(), this.loadSite()])
            },
        },
        created() {
            AOS.init({
                duration: 400,
                easing: 'ease-in-out-sine',
                once: true,
                offset: 60
            });

            this.loadToken()

            const urlParams = new URLSearchParams(window.location.search);
            const partnerReference = urlParams.get('partner_reference');

            if (partnerReference) {
                this.dataStore.setPartnerReference(partnerReference)
            }
        }
    }
</script>
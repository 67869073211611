<template>
    <DefaultLayout v-if="page">
        <Masthead v-if="masthead"
                  :background-colour="masthead.value.background_colour"
                  :height="masthead.value.height"
                  :text-colour="masthead.value.text_colour"
                  :title="masthead.value.title"
                  :sub-title="masthead.value.sub_title"
                  :text="masthead.value.text"
                  :image="masthead.value.image ? masthead.value.image.url : null"
        />
        <TextMasthead v-else :title="page.title" :center-text="true" />
        <main>
            <ContentSections :pageContent="page"/>
        </main>
    </DefaultLayout>
</template>

<script>
    import Masthead from '@/components/Masthead.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import ContentSections from '@/components/ContentSections.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import PageContent from '@/mixins/pageContent'

    export default {
        name: 'General',
        inject: ['sdk', 'dataStore'],
        mixins: [PageContent],
        components: {
            Masthead,
            TextMasthead,
            ContentSections,
            DefaultLayout
        },
        data() {
            return {
                redirect404: true
            };
        },
        computed: {
            masthead: function () {
                return this.getPageContentByKey('masthead')
            }
        },
        created() {
            this.loadPageContent();
        }
    }
</script>
<template>
    <section :class="componentThemeClass">
        <Container class="py-16 lg:py-32">
            <div class="text-center max-w-4xl mx-auto">
                <h2 data-aos="fade-right">{{ title }}</h2>
            </div>
            <div v-if="brandImages" class="grid items-center gap-4 mt-12 lg:gap-8 lg:grid-flow-col">
                <img v-for="(brandImage, index) in brandImages" :key="index" :src="brandImage.image.url"
                     :alt="'Image: ' + brandImage.image.title" class="mb-4 mx-auto max-h-12 lg:mb-0" data-aos="fade-up" :data-aos-delay="index * 50"
                     loading="lazy" />
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'BrandSection',
        mixins: [ComponentTheme],
        components: {Container},
        props: {
            title: String,
            brandImages: Array
        }
    }
</script>
<template>
    <DefaultLayout>
        <TextMasthead title="Your Orders"/>
        <main>
            <section>
                <Container class="py-16">
                    <div class="grid grid-cols-12 gap-4 lg:gap-16">
                        <div class="mb-8 col-span-12 lg:mb-0 lg:col-span-3" data-aos="fade-right">
                            <AccountNavigation/>
                        </div>
                        <div class="col-span-12 lg:col-span-9" data-aos="fade-left">
                            <div v-if="orders.length === 0" class="text-center text-xl">No order history</div>
                            <template v-else>
                                <div class="overflow-scroll mb-8">
                                    <table class="w-full">
                                        <tr class="border-b">
                                            <th class="text-left font-light py-4">Reference</th>
                                            <th class="text-left font-light py-4">Total</th>
                                            <th class="text-left font-light py-4">Status</th>
                                            <th class="text-right font-light py-4">Date</th>
                                        </tr>
                                        <tr v-for="order in orders" :key="order.id" class="align-top border-b">
                                            <td class="py-4">
                                                <p class="text-xl mb-0">
                                                    <router-link :to="{name: 'order', params: {id: order.id}}"
                                                                 :title="'Order ' + order.code">
                                                        {{ order.code }}
                                                    </router-link>
                                                </p>
                                            </td>
                                            <td class="py-4">
                                                <p class="mb-0">{{ moneyFormat(order.total) }}</p>
                                            </td>
                                            <td class="py-4">
                                                <p class="mb-0">{{ order.status.name }}</p>
                                            </td>
                                            <td class="py-4 text-right">
                                                <p class="mb-0">{{  new Date(order.created_at).toDateString() }}</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <ul v-if="totalPages && totalPages > 1" class="flex justify-center">
                                    <li v-for="pageNumber in totalPages" :key="pageNumber">
                                        <a @click.prevent="paginationChange(pageNumber)" class="px-3"
                                           :class="{'font-semibold': pageNumber === page}">{{ pageNumber }}</a>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                </Container>
            </section>
        </main>
    </DefaultLayout>
</template>

<script>
    import Container from '@/components/Container.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccountNavigation from '@/components/AccountNavigation.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import MoneyFormat from '@/mixins/moneyFormat'

    export default {
        name: 'AccountOrderListPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [MoneyFormat],
        components: {
            Container,
            TextMasthead,
            AccountNavigation,
            DefaultLayout
        },
        data() {
            return {
                totalPages: null,
                pageNumber: 1,
                pagination_interval: 16,
                sorting: {
                    property: 'created_at',
                    direction: 'desc'
                },
                orders: []
            };
        },
        computed: {
            user: function () {
                return this.dataStore.getUser()
            },
            pagination: function () {
                return {
                    page: this.pageNumber,
                    pagination_interval: this.pagination_interval
                }
            },
        },
        methods: {
            paginationChange: function (page) {
                this.pageNumber = page
                this.loadCustomerOrders()
            },
            loadCustomerOrders: function () {
                this.sdk.getCustomerOrders(this.user.customer.id, this.pagination, [], [this.sorting])
                    .then(response => {
                        this.orders = response.orders
                        this.totalPages = response.last_page
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            }
        },
        created() {
            if (this.user.customer) {
                this.loadCustomerOrders()
            }
        }
    }
</script>
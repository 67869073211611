<template>
    <Head v-if="loaded">
        <title>{{ siteTitle }} | {{ pageTitle }}</title>
        <meta name="description" :content="pageDescription">
    </Head>
    <DefaultLayout v-if="loaded">
        <TextMasthead :title="pageTitle" :sub-title="tag ? tag.name : null" :text="pageIntro" />
        <main>
            <section>
                <Container class="pb-16 lg:pb-32">
                    <div class="grid gap-4 lg:grid-cols-12 lg:gap-8">
                        <div class="relative lg:col-span-3">
                            <div class="flex items-center justify-between lg:hidden">
                                <a @click.prevent="filtersOpen = !filtersOpen" class="text-xl">
                                    <AdjustmentsHorizontalIcon class="inline h-6 w-6 mr-2" /> Filters ({{selectedCategoriesCount}})
                                </a>
                                <select @change="sortChange($event)" class="bg-transparent border-0 text-xl px-0 background-none pr-12">
                                    <option v-for="(option, index) in sortingOptions" :key="index"
                                            :selected="sorting.property === option.property && sorting.direction === option.direction"
                                            :value="option.property + '|' + option.direction">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>

                            <div class="w-[450px] filter border drop-shadow-lg absolute top-8 left-0 px-6 pt-2 pb-6 z-10 bg-white max-h-screen overflow-scroll lg:border-none lg:drop-shadow-none lg:p-0 lg:top-0 lg:w-auto lg:relative lg:block" :class="[filtersOpen ? 'block' : 'hidden']">
                                <template v-for="(cat, index) in categories" :key="index">
                                    <Accordion v-if="!category || category && category.id !== cat.id" :title="cat.title" class="py-4">
                                        <div class="pr-2">
                                            <template  v-for="option in cat.category_options" :key="option.id">
                                                <label :for="option.name" class="block flex justify-between mb-1">{{ option.name }} <input :id="option.name" type="checkbox" @change="filterChange()" v-model="selectedCategories[cat.id]" :value="option.id"></label>
                                            </template>
                                        </div>
                                    </Accordion>
                                </template>
                                <Button @click.prevent="resetCategoryFilters(true)" background-colour="transparent" text-colour="black" class="w-full mt-6">
                                    Reset
                                </Button>
                            </div>
                        </div>
                        <div class="min-h-24 lg:col-span-9">
                            <div class="hidden justify-end mb-6 lg:flex">
                                <select @change="sortChange($event)" class="bg-transparent border-0 text-xl px-0 background-none pr-12">
                                    <option v-for="(option, index) in sortingOptions" :key="index"
                                            :selected="sorting.property === option.property && sorting.direction === option.direction"
                                            :value="option.property + '|' + option.direction">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div v-if="products && products.length > 0"
                                 class="grid grid-cols-12 gap-4 mb-8 lg:gap-8 lg:mb-16">
                                <div v-for="(product, index) in products" :key="index" class="col-span-6 lg:col-span-3" data-aos="fade-in" :data-aos-delay="index">
                                    <Product :product="product" />
                                </div>
                            </div>
                            <div v-if="!this.loadingProducts && (!products || products.length === 0)">
                                <p class="text-2xl mb-0 h-24 flex justify-center items-center">
                                    <span>No results found</span>
                                </p>
                            </div>
                            <template v-if="this.loadingProducts === true">
                                <div class="relative h-24 mt-24">
                                    <Loading/>
                                </div>
                            </template>
                            <div v-if="showLoadMore" class="mt-6 lg:mt-8" data-aos="fade-up">
                                <span v-if="this.products.length > 0" class="text-sm text-center block">
                                    Showing <span class="font-semibold">{{ this.products.length }}</span> of <span class="font-semibold">{{ totalProducts }}</span> products
                                </span>
                                <Button @click.prevent="loadMoreProducts" text-colour="black"
                                        :class="'mt-2 mx-auto w-full block lg:max-w-xl'">{{ this.loadingProducts ? 'Loading...' : 'Load more' }}</Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <ContentSections v-if="page" :pageContent="page" class="border border-t" />
        </main>
    </DefaultLayout>
</template>

<script>
    import TextMasthead from '@/components/TextMasthead.vue'
    import ContentSections from '@/components/ContentSections.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import Product from '@/components/elements/Product.vue'
    import Button from '@/components/elements/Button.vue'
    import PageMeta from '@/mixins/pageMeta'
    import PageContent from '@/mixins/pageContent'
    import MoneyFormat from '@/mixins/moneyFormat'
    import Loading from '@/components/Loading.vue'
    import Container from '@/components/Container.vue'
    import Accordion from '@/components/elements/Accordion.vue'
    import { AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline'
    import axios from 'axios'
    import { Head } from '@vueuse/head'

    export default {
        name: 'ProductListPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageMeta, PageContent, MoneyFormat],
        components: {
            Container,
            Loading,
            TextMasthead,
            ContentSections,
            DefaultLayout,
            Product,
            Button,
            Accordion,
            AdjustmentsHorizontalIcon,
            Head
        },
        data() {
            return {
                pageNumber: 1,
                pagination_interval: 16,
                totalProducts: 0,
                sorting: {
                    property: 'min_price',
                    direction: 'asc'
                },
                sortingOptions: [
                    {
                        property: 'created_at',
                        direction: 'desc',
                        label: 'Newest'
                    },
                    {
                        property: 'min_price',
                        direction: 'asc',
                        label: 'Price Low to High'
                    },
                    {
                        property: 'min_price',
                        direction: 'desc',
                        label: 'Price High to Low'
                    },
                    {
                        property: 'title',
                        direction: 'asc',
                        label: 'Title (A-Z)'
                    },
                    {
                        property: 'title',
                        direction: 'desc',
                        label: 'Title (Z-A)'
                    }
                ],
                filters: [],
                selectedCategories: [],
                products: null,
                productType: null,
                category: null,
                categoryOption: null,
                tag: null,
                categories: null,
                filtersOpen: false,
                showLoadMore: false,
                loadingProducts: false,
                loaded: false
            };
        },
        computed: {
            selectedCategoriesCount: function () {
                let count = 0
                this.selectedCategories.forEach(value => {
                    count += value.length
                })

                return count
            },
            pageSlug: function () {
                if (this.$route.params.type) {
                    return this.$route.params.type
                }

                if (this.$route.params.category) {
                    return this.$route.params.category + '-' + this.$route.params.option
                }

                return 'products';
            },
            pageTitle: function () {
                if (this.productType) {
                    return this.productType.title
                }

                if (this.categoryOption) {
                    return this.categoryOption.name
                }

                return 'All products';
            },
            pageDescription: function () {
                if (this.productType && this.productType.description) {
                    return this.productType.description
                }

                if (this.categoryOption && this.categoryOption.description) {
                    return this.categoryOption.description
                }

                return this.defaultPageDescription
            },
            pageIntro: function () {
                if (this.productType && this.productType.description) {
                    return this.productType.description
                }

                if (this.categoryOption && this.categoryOption.description) {
                    return this.categoryOption.description
                }

                return null;
            },
            pagination: function () {
                return {
                    page: this.pageNumber,
                    pagination_interval: this.pagination_interval
                }
            },
        },
        methods: {
            loadMoreProducts: function () {
                this.pageNumber++
                this.loadProducts(true, this.pagination, this.filters, [this.sorting]);
            },
            filterChange: function () {
                this.pageNumber = 1;
                this.totalProducts = 0
                let filters = []
                this.selectedCategories.forEach(value => {
                    if(value.length > 0) {
                        filters.push(
                            {
                                property: 'category_options',
                                value: value,
                                operator: 'whereIn'
                            }
                        )
                    }
                })

                this.filters = filters
                this.loadProducts(false, this.pagination, this.filters, [this.sorting]);
            },
            resetCategoryFilters: function(reloadProducts = false) {
                if(this.categories) {
                    this.categories.forEach(category => {
                        this.selectedCategories[category.id] = []
                    })
                }

                if(reloadProducts) {
                    this.loadProducts(false, this.pagination, this.filters, [this.sorting])
                    this.filtersOpen = false
                }
            },
            sortChange: function (event) {
                const values = event.target.value.split('|')
                this.sorting = {
                    property: values[0],
                    direction: values[1]
                }
                this.loadProducts(false, this.pagination, this.filters, [this.sorting]);
            },
            async loadProducts(append = false, pagination = {page: 1}, filters = [], sort = [], includes = ['childProducts']) {
                this.loadingProducts = true
                if (!append) {
                    this.products = [];
                    this.showLoadMore = false
                }

                let defaultFilters = [
                    {
                        property: 'parent_product_id',
                        value: 'null',
                        operator: '='
                    },
                    {
                        property: 'inventory',
                        value: 1,
                        operator: '>='
                    }
                ]

                if(this.productType) {
                    defaultFilters.push({
                        property: 'product_type_id',
                        value: this.productType.id,
                        operator: '='
                    })
                }

                if(this.categoryOption) {
                    defaultFilters.push(
                        {
                            property: 'category_options',
                            value: this.categoryOption.id,
                            operator: '='
                        }
                    )
                }

                if(this.tag) {
                    defaultFilters.push({
                        property: 'tags',
                        value: this.tag.id,
                        operator: '='
                    })
                }

                return this.sdk.getProducts(pagination, defaultFilters.concat(filters), sort, includes)
                    .then(response => {
                        if (append) {
                            this.products = [...this.products, ...response.products]
                        } else {
                            this.products = response.products;
                        }

                        this.showLoadMore = response.current_page !== response.last_page
                        this.totalProducts = response.count
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                    }).finally(() => {
                        this.loadingProducts = false
                    });
            },
            async loadProductType () {
                const typeSlug = this.$route.params.type;
                return this.sdk.getProductTypeBySlug(typeSlug)
                    .then(response => {
                        if (response.product_types.length === 0) {
                            this.$router.push({name: '404'});
                            return;
                        }

                        this.productType = response.product_types[0];
                        this.categories = response.product_types[0].categories;
                        this.resetCategoryFilters()
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            async loadCategory () {
                const categorySlug = this.$route.params.option;
                return this.sdk.getCategoryOptionBySlug(categorySlug)
                    .then(response => {
                        if (response.category_options.length === 0) {
                            this.$router.push({name: '404'});
                            return;
                        }

                        this.categoryOption = response.category_options[0];
                        this.category = response.category_options[0].category;
                        this.resetCategoryFilters()
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                    })
            },
            async loadCategories () {
                return this.sdk.getCategories()
                    .then(response => {
                        this.categories = response.categories;
                        this.resetCategoryFilters()
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            async loadTag() {
                if (this.$route.query.tag) {
                    return this.sdk.getTagBySlug(this.$route.query.tag)
                        .then(response => {
                            this.tag = response.tags[0]
                        }).catch(error => {
                            this.errorHandler.handleError(error);
                        }).finally(() => {
                            this.loadingProducts = false
                        });
                }
            },
            async loadComponentData(){
                if (this.$route.params.category) {
                    return await axios.all([this.loadPageContent(), this.loadCategory(), this.loadCategories(), this.loadTag()])
                }

                if (this.$route.params.type) {
                    return await axios.all([this.loadPageContent(), this.loadProductType(), this.loadTag()])
                }

                return await axios.all([this.loadPageContent(), this.loadCategories(), this.loadTag()])
            }
        },
        created() {
            this.loadComponentData()
                .catch(error => {
                    this.errorHandler.handleError(error);
                })
                .finally(() => {
                    this.loaded = true
                    this.loadProducts(false, this.pagination, this.filters, [this.sorting])
                })
        }
    }
</script>
<template>
    <RelatedProductSection :title="title" :link-text="linkText" :link-url="linkUrl" :products="products" :background-colour="_backgroundColour"
                           :text-colour="_textColour"/>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import RelatedProductSection from '@/components/sections/RelatedProductSection.vue'

    export default {
        name: 'TaggedProductSection',
        inject: ['sdk', 'errorHandler'],
        mixins: [ComponentTheme],
        components: {RelatedProductSection},
        props: {
            title: String,
            linkUrl: String,
            linkText: String,
            tagSlug: String
        },
        data() {
            return {
                tag: [],
                products: []
            }
        },
        methods: {
            loadTag: function () {
                this.sdk.getTagBySlug(this.tagSlug)
                    .then(response => {
                        this.tag = response.tags[0]
                        this.loadProducts()
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            loadProducts: function () {
                const filters = [
                    {
                        property: 'tags',
                        value: this.tag.id,
                        operator: '='
                    },
                    {
                        property: 'inventory',
                        value: 1,
                        operator: '>='
                    }
                ]

                this.sdk.getProducts({page: 1}, filters, [], ['childProducts'])
                    .then(response => {
                        this.products = response.products
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            }
        },
        created() {
            this.loadTag()
        }
    }
</script>
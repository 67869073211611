<template>
    <section :class="componentThemeClass">
        <Container class="py-16 lg:py-32">
            <h2 v-if="title" class="mb-8" data-aos="fade-right">{{ title }}</h2>
            <div class="grid text-center grid-cols-12 gap-4 lg:gap-8">
                <div v-for="(panel, index) in panels" :key="index" class="col-span-12 lg:col-span-4" data-aos="fade-in" :data-aos-delay="index * 50">
                    <img v-if="panel.image" :src="panel.image.url" :alt="'Image: ' + panel.title" loading="lazy" />
                    <h3 class="h2 font-heading mt-4 mb-6">{{ panel.title }}</h3>
                    <span v-html="panel.content" class="prose" :class="'prose-' + _textColour"></span>
                </div>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'ContentGridSection',
        mixins: [ComponentTheme],
        components: {Container},
        props: {
            title: String,
            linkUrl: String,
            panels: Array
        }
    }
</script>
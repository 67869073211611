<template>
    <transition name="fade" mode="out-in" data-aos="fade-in" data-aos-duration="500">
        <div v-if="basketOpen" class="filter drop-shadow-lg fixed m-auto bottom-0 left-0 right-0 z-10 max-w-2xl bg-white text-black border border-gray-100 lg:absolute lg:left-auto lg:bottom-auto lg:max-w-auto">
            <div class="p-4">
                <div class="relative mb-4">
                    <h4 class="mb-0">Added to bag <CheckIcon class="inline h-6 w-6" /></h4>
                    <a class="absolute top-0 right-0" @click.prevent="basketToggle(false)">
                        <XMarkIcon class="inline h-6 w-6" />
                    </a>
                </div>
                <template v-for="(basketProduct, index) in basket.products" :key="index">
                    <div v-if="basketProducts.includes(basketProduct.product.id.toString())" class="flex flex-row items-center mb-4">
                        <div v-if="getThumbnail(basketProduct.product)" class="mr-4 lg:mr-8">
                            <a :href="$router.resolve({name: 'product', params: { slug: basketProduct.product.parent_product.slug }}).href" :title="basketProduct.product.parent_product.name">
                                <img class="w-full max-w-[50px] lg:max-w-[100px]" :src="getThumbnail(basketProduct.product).url" :alt="'Image: ' + basketProduct.product.name" loading="lazy" />
                            </a>
                        </div>
                        <div>
                            <h3 class="mb-3 max-w-[300px]">
                                {{ basketProduct.product.name }}
                                <span class="mt-2 block text-base">Colour: {{ productCategory(basketProduct.product, 'colour').name }}</span>
                                <span class="mt-2 block text-base">Size: {{ productCategory(basketProduct.product, 'size').name }}</span>
                            </h3>
                            <p class="mb-3 flex justify-between lg:justify-start">
                                <span class="font-semibold mr-1">{{ moneyFormat(formatBasketProductPrice(basketProduct, basketProduct.quantity)) }}
                                    <span class="font-normal text-xs">Ex. VAT</span>
                                </span>
                            </p>
                            <p>Qty {{basketProduct.quantity}}</p>
                        </div>
                    </div>
                </template>

                <div class="flex flex-col justify-between lg:space-x-4 lg:flex-row">
                    <LinkButton :href="$router.resolve({name: 'basket'}).href" title="Basket" text-colour="black" class="flex-1 mb-2">View Basket</LinkButton>
                    <LinkButton :href="$router.resolve({name: 'checkout', params: { basket_reference: basket.basket_reference}}).href" title="Checkout" background-colour="black"  text-colour="white" class="flex-1 mb-2">Checkout</LinkButton>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline'
    import MoneyFormat from '@/mixins/moneyFormat'
    import BasketActions from '@/mixins/basketActions'
    import ProductContent from '@/mixins/productContent'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'BasketAlert',
        inject: ['sdk', 'dataStore'],
        mixins: [MoneyFormat, BasketActions, ProductContent],
        components: {
            XMarkIcon, CheckIcon, LinkButton
        },
        data() {
            return {
                basketOpen: false,
                basketProducts: []
            }
        },
        methods: {
            basketToggle: function (open = null) {
                if(open !== null){
                    this.basketOpen = open
                }
                else {
                    this.basketOpen = !this.basketOpen
                }
            },
        },
        created() {
            this.emitter.on('basket-updated', e => {
                this.basketProducts = e
                this.basketToggle(true)
            })
        }
    }
</script>




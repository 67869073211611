<template>
    <Head v-if="category">
        <title>{{ siteTitle }} | {{ category.title }}</title>
        <meta name="description" :content="pageDescription">
    </Head>
    <DefaultLayout>
        <template v-if="category">
            <Masthead v-if="category.thumbnail" :title="category.title" :sub-title="category.description" :image="category.thumbnail.url"
                      text-colour="white"
                      background-colour="primary"
            />
            <TextMasthead v-else :title="category.title" :sub-title="category.description" :center-text="true" background-colour="primary" />
            <main>
                <section class="bg-primary">
                    <Container class="py-16 lg:pb-32">
                        <div class="min-h-24">
                            <div v-if="category.category_options && category.category_options.length > 0"
                                 class="grid grid-cols-12 gap-4 mb-8 lg:gap-8 lg:mb-16">
                                <div v-for="(categoryOption, index) in category.category_options" :key="index" class="drop-shadow-md relative bg-[length:80%_auto] bg-no-repeat bg-center bg-white col-span-6 aspect-square lg:col-span-3"
                                     data-aos="fade-in"
                                     :style="categoryOption.thumbnail ? {backgroundImage: `url('${categoryOption.thumbnail.url}')`} : {}"
                                >
                                    <a :href="$router.resolve({name: 'product-categories', params: { category: category.slug, option: categoryOption.value}}).href"
                                       :title="'Shop ' + categoryOption.name"
                                    >
                                        <div class="transition-all bg-[rgba(255,255,255,0.9)] absolute m-auto top-0 left-0 bottom-0 right-0 p-4  flex items-center justify-center"
                                             :class="{'opacity-0 hover:opacity-100': categoryOption.thumbnail }"
                                        >
                                            <div class="text-center">
                                                <h3 class="h2 mb-0">{{ categoryOption.name }}</h3>
                                                <p v-if="categoryOption.description" class="mt-2 mb-0 text-sm lg:text-base">{{ categoryOption.description.split('.')[0] }}.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        </template>
    </DefaultLayout>
</template>

<script>
    import Container from '@/components/Container.vue'
    import Masthead from '@/components/Masthead.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import Product from '@/components/elements/Product.vue'
    import PageMeta from '@/mixins/pageMeta'
    import PageContent from '@/mixins/pageContent'
    import IntroTextSection from '@/components/sections/IntroTextSection.vue'
    import { Head } from '@vueuse/head'

    export default {
        name: 'CollectionPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageMeta, PageContent],
        components: {
            Container,
            Masthead,
            TextMasthead,
            DefaultLayout,
            Product,
            IntroTextSection,
            Head
        },
        data() {
            return {
                category: null
            }
        },
        computed: {
            pageDescription: function () {
                if (this.category && this.category.description) {
                    return this.category.description
                }

                return this.defaultPageDescription
            },
        },
        methods: {
            loadCategory: function () {
                const categorySlug = this.$route.params.slug;

                this.sdk.getCategoryBySlug(categorySlug)
                    .then(response => {
                        this.category = response.categories[0]
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
        },
        created() {
            this.loadCategory()
        }
    }
</script>
<template>
    <section :class="componentThemeClass">
        <Container class="py-16 lg:py-32 lg:max-w-80">
            <h2 v-if="title" class="mb-8" data-aos="fade-left">{{ title }}</h2>
            <div class="grid gap-4 mb-4 lg:mb-8 lg:grid-cols-4" :class="[imageGap]">
                <template v-for="(panel, index) in panels" :key="index">
                    <div @click="[toggler = !toggler, selectedPanel = panel]" class="bg-cover bg-center text-white aspect-square px-8" :class="[panel.span ? 'lg:col-span-' + panel.span + ' lg:aspect-[' +  panel.span  + '/1]' : '']" data-aos="fade-in" :data-aos-delay="index * 50" :style="{backgroundImage: `url('${panel.image.url}')`}">
                        <a :href="panel.link_url" :title="panel.title ? panel.title : ''" class="flex items-center justify-center h-full w-full">
                            <div class="text-center">
                                <h3 class="mb-0 uppercase font-semibold text-shadow">{{ panel.title }}</h3>
                                <p v-if="panel.content" class="whitespace-pre-line mt-4">{{ panel.content }}</p>
                            </div>
                        </a>
                    </div>
                </template>
            </div>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'
    import ComponentTheme from '@/mixins/componentTheme'

    export default {
        name: 'ImageGridSection',
        mixins: [ComponentTheme],
        components: {Container},
        props: {
            title: String,
            gapSize: String,
            itemsPerRow: String,
            enableGallery: Boolean,
            panels: Array
        },
        data() {
            return {
                modalImage: false,
                toggler: false
            }
        },
        computed: {
            imageGap: function(){
                if (this.gapSize) {
                    if (this.gapSize === 'none') {
                        return 'lg:gap-0';
                    } else if (this.gapSize === 'small') {
                        return 'lg:gap-2';
                    } else if (this.gapSize === 'medium') {
                        return 'lg:gap-4';
                    } else if (this.gapSize === 'large') {
                        return 'lg:gap-8';
                    }
                }

                return 'lg:gap-4';
            }
        }
    }
</script>
<template>
    <a :href="$router.resolve({name: 'product', params: { slug: product.slug }}).href" :title="product.title">
        <div class="relative bg-white overflow-hidden pb-[100%]">
            <img v-if="colourVariant && getThumbnail(colourVariant)" :src="getThumbnail(colourVariant).url" :alt="'Image: ' + product.title" class="left-0 right-0 top-0 bottom-0 max-h-full max-w-full m-auto absolute"
                 loading="lazy" />
            <img v-else-if="getThumbnail(product)" :src="getThumbnail(product).url" :alt="'Image: ' + product.title" class="left-0 right-0 top-0 bottom-0 max-h-full max-w-full m-auto absolute"
                 loading="lazy" />
        </div>

        <div v-if="colourVariants(product).length > 0" class="grid grid-cols-6 gap-1 mt-2 mb-2 items-center">
            <template v-for="childProduct in colourVariants(product).slice(0, childProductsToShow)" :key="childProduct.id">
                <img v-if="getColourImage(childProduct)" :src="getColourImage(childProduct)" :alt="'Image: ' + childProduct.title" loading="lazy" />
                <span v-else>{{ childProduct.title }}</span>
            </template>
            <p v-if="colourVariants(product).length - (childProductsToShow + 1) > 0" class="text-xs m-0">
                +{{ colourVariants(product).length - (childProductsToShow)}} more
            </p>
        </div>

        <h3 class="mt-4 mb-2">{{ product.title }}</h3>

        <p class="mb-0 flex justify-between text-[#777976]">
            <span class="mr-1">From {{ moneyFormat(taxFormat((product.min_price * 0.8), includeTax)) }}</span>
        </p>
    </a>
</template>

<script>
    import MoneyFormat from '@/mixins/moneyFormat'
    import ProductContent from '@/mixins/productContent'

    export default {
        name: 'Product',
        inject: ['dataStore'],
        mixins: [MoneyFormat, ProductContent],
        props: {
            product: Object,
            childProductsToShow: {
                type: Number,
                default: 5
            }
        },
        computed: {
            includeTax: function () {
                return this.dataStore.getIncludeTax()
            },
            colourVariant: function () {
                const variants = this.colourVariants(this.product)
                return variants.length > 0 ? variants[0] : null
            }
        }
    }
</script>
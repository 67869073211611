<template>
    <header class="masthead relative" :class="[componentThemeClass]">
        <carousel :items-to-show="1" :autoplay="5000" :wrap-around="true">
            <slide v-for="(slide, index) in slides" :key="index" class="masthead aspect-[4/3] lg:aspect-[16/5]">
                <div v-if="slide.text" class="flex items-center justify-center h-full w-full bg-cover bg-right-top"
                     :style="slide.image ? {backgroundImage: `url('${slide.image.url}')`} : {}">
                    <a :href="slide.link_url" :title="slide.title">
                        <div class="absolute top-0 bottom-0 left-0 right-0 h-full w-full bg-black opacity-10"></div>
                        <Container class="text-center lg:max-w-4xl" animation="fade-in">
                            <template v-if="slide.title">
                                <h1 v-if="index === titleSlide" class="text-shadow-md">{{ slide.title }}</h1>
                                <h2 v-else class="h1 text-shadow-md">{{ slide.title }}</h2>
                            </template>
                            <p v-if="slide.text" class="whitespace-pre-line mb-0 mt-8 text-lg">{{ slide.text }}</p>
                            <span v-if="slide.link_text" class="uppercase font-semibold transition-all text-center py-3 px-6 border disabled:opacity-50 disabled:pointer-events-none mt-8 inline-block text-white border-white">
                                 {{ slide.link_text }}
                            </span>
                        </Container>
                    </a>
                </div>
                <div v-else>
                    <a :href="slide.link_url" :title="slide.title">
                        <template v-if="slide.title">
                            <h1 v-if="index === titleSlide" class="hidden">{{ slide.title }}</h1>
                            <h2 v-else class="hidden">{{ slide.title }}</h2>
                        </template>
                        <img :src="slide.image.url" :alt="'Image' + index" class="w-full" />
                    </a>
                </div>
            </slide>

            <template #addons>
                <Container>
                    <pagination />
                </Container>
            </template>
        </carousel>
    </header>
</template>

<script>
    // If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination } from 'vue3-carousel'
    import ComponentTheme from '@/mixins/componentTheme'
    import Container from '@/components/Container.vue'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'MastheadCarousel',
        mixins: [ComponentTheme],
        components: {
            Carousel,
            Slide,
            Pagination,
            Container,
            LinkButton
        },
        props: {
            slides: Array
        },
        computed: {
            titleSlide: function () {
                let index = null
                this.slides.forEach((slide, i) => {
                    if(slide.title && index === null){
                        index = i
                    }
                })

                return index
            }
        },
    };
</script>

<style>
    :root {
        --vc-clr-primary: rgba(255, 255, 255, 1);
        --vc-clr-secondary: rgba(255, 255, 255, 0.2);
        --vc-pgn-width: 50px;
    }

    .carousel__pagination{
        position: absolute;
        bottom: 1.5rem;
        right: 0;
        left: 0;
    }

    .carousel__pagination-button {
        background-color: var(--vc-pgn-background-color) !important;
    }

    .carousel__pagination-button--active {
        background-color: var(--vc-pgn-active-color) !important;
    }

    @media (min-width: 1024px) {
        .carousel__pagination{
            left: initial;
        }
    }
</style>
export default {
    methods: {
        moneyFormat: function (price) {
            return '£' + (price ? price: 0).toFixed(2);
        },
        taxFormat: function (price, includeTax = false) {
            if(this.dataStore.getStore().default_tax_rate && includeTax){
                return price + (price * (this.dataStore.getStore().default_tax_rate.rate / 100))
            }

            return price;
        }
    }
}
export default {
    computed: {
        basket: function () {
            return this.dataStore.getBasket()
        },
        basketSubTotal: function () {
            return this.basket ? this.basket.sub_total : 0
        },
        basketTaxTotal: function () {
            return this.basket ? this.basket.tax_total : 0
        },
        basketDiscountTotal: function () {
            return this.basket ? this.basket.discount_total : 0
        },
        basketTotal: function () {
            return this.basket ? this.basket.total : 0
        },
        basketItemTotal: function () {
            let total = 0

            if(this.basket) {
                this.basket.products.forEach((product) => {
                    total += product.quantity
                })
            }

            return total
        },
        basketHasProducts: function () {
            return this.basket && this.basket.products.length > 0
        },
    },
    methods: {
        formatBasketProductPrice: function (basketProduct, quantity = 1) {
            return basketProduct.product.discount_price * quantity;
        },
        updateProductQuantity: function (quantity, productId) {
            if (quantity <= 0) {
                return false;
            }

            this.sdk.addProductToBasket(this.dataStore.getBasketReference(), productId, quantity)
                .then(response => {
                    const basket = response.basket;

                    this.dataStore.setBasket(basket);
                }).catch(error => {
                console.log(error);
            })
        },
        removeProductFromBasket: function (productId) {
            this.sdk.deleteProductFromBasket(this.dataStore.getBasketReference(), productId)
                .then(response => {
                    const basket = response.basket;

                    this.dataStore.setBasket(basket);
                }).catch(error => {
                console.log(error);
            })
        },
    }
}
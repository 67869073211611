<template>
    <ul class="text-2xl lg:sticky lg:top-0" data-aos="fade-right">
        <li class="border-b">
            <router-link :to="{name: 'orders'}" title="Orders" class="py-2 block">Your Orders</router-link>
        </li>
        <li class="border-b">
            <router-link :to="{name: 'details'}" title="Details" class="py-2 block">Your Details</router-link>
        </li>
        <li class="border-b">
            <router-link :to="{name: 'address'}" title="Addresses" class="py-2 block">Your Addresses</router-link>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'AccountNavigation'
    }
</script>
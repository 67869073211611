<template>
    <DefaultLayout>
        <TextMasthead />
        <main>
            <section>
                <Container class="py-16 lg:py-32 lg:max-w-4xl" animation="fade-up">
                    <h1 class="mb-8">Page not found</h1>
                    <p class="text-2xl mb-16">Sorry, the page you were looking for does not exist.</p>
                    <LinkButton :href="$router.resolve({name: 'home'}).href" title="Home" text-colour="black">Back to homepage</LinkButton>
                </Container>
            </section>
        </main>
    </DefaultLayout>
</template>

<script>
    import TextMasthead from '@/components/TextMasthead.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import Container from '@/components/Container.vue'
    import LinkButton from '@/components/elements/LinkButton.vue'

    export default {
        name: 'NotFoundPage',
        inject: ['dataStore'],
        components: {
            Container,
            TextMasthead,
            DefaultLayout,
            LinkButton
        }
    }
</script>
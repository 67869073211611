<template>
    <div class="border-b" :class="componentThemeClass + ' border-' + _textColour">
        <a @click.prevent="accordionOpen = !accordionOpen" data-aos="fade-right">
            <h3 class="mb-0 flex items-center justify-between">{{ title }}
                <ChevronUpIcon v-if="accordionOpen" class="inline h-8 w-8"/>
                <ChevronDownIcon v-else class="inline h-8 w-8"/>
            </h3>
        </a>
        <div class="mt-4" :class="{'hidden' : !accordionOpen}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/vue/24/outline'

    export default {
        name: 'Accordion',
        mixins: [ComponentTheme],
        components: {
            ChevronDownIcon, ChevronUpIcon
        },
        props: {
            title: String,
            open: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                accordionOpen: this.open
            }
        }
    }
</script>
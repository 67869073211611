<template>
    <DefaultLayout v-if="page">
        <MastheadCarousel v-if="masthead"
                  :background-colour="masthead.value.background_colour"
                  :text-colour="masthead.value.text_colour"
                  :slides="masthead.value.slides"
        />
        <main>
            <ContentSections :pageContent="page"/>
        </main>
    </DefaultLayout>
</template>

<script>
    import MastheadCarousel from '@/components/MastheadCarousel.vue'
    import ContentSections from '@/components/ContentSections.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import PageContent from '@/mixins/pageContent'

    export default {
        name: 'HomePage',
        inject: ['sdk', 'dataStore'],
        mixins: [PageContent],
        components: {
            MastheadCarousel,
            ContentSections,
            DefaultLayout
        },
        computed: {
            pageSlug: function () {
                return 'home';
            },
            masthead: function () {
                return this.getPageContentByKey('masthead_carousel')
            },
        },
        created() {
            this.loadPageContent();
        }
    }
</script>
<template>
    <DefaultLayout>
        <TextMasthead title="Your basket" />
        <main>
            <section>
                <Container>
                    <p v-if="!basket || basket.products.length === 0" class="text-2xl mb-0" data-aos="fade-up">Your basket is empty</p>
                    <table v-if="basketHasProducts" class="w-full">
                        <tr class="border-black border-b">
                            <th class="text-left font-semibold uppercase py-4">Product</th>
                            <th class="hidden text-left font-semibold uppercase py-4 lg:block">Price</th>
                            <th class="text-center font-semibold uppercase py-4">Quantity</th>
                            <th class="text-right font-semibold uppercase py-4">Total</th>
                        </tr>
                        <tr v-for="(basketProduct, index) in basket.products" :key="index" class="align-top">
                            <td class="py-4">
                                <div class="flex flex-col lg:flex-row">
                                    <a :href="$router.resolve({name: 'product', params: { slug: basketProduct.product.parent_product.slug }}).href" :title="basketProduct.product.parent_product.name">
                                        <img v-if="getThumbnail(basketProduct.product)" class="w-full max-w-[150px] lg:max-w-[200px] lg:mr-4"
                                         :src="getThumbnail(basketProduct.product).url" :alt="'Image: ' + basketProduct.product.name" loading="lazy" />
                                    </a>
                                    <div>
                                        <h3 class="my-2 max-w-[300px] lg:mt-0">
                                            {{ basketProduct.product.name }}
                                            <span class="mt-2 block text-base">Colour: {{ productCategory(basketProduct.product, 'colour').name }}</span>
                                            <span class="mt-2 block text-base">Size: {{ productCategory(basketProduct.product, 'size').name }}</span>
                                        </h3>
                                        <p v-if="basketProduct.product.description" class="mb-0 lg:max-w-xl">{{ basketProduct.product.description }}</p>
                                    </div>
                                </div>
                            </td>
                            <td class="hidden py-4 lg:block">
                                <p class="text-xl">{{ moneyFormat(formatBasketProductPrice(basketProduct)) }}</p>
                            </td>
                            <td class="py-4">
                                <span class="flex items-center justify-center">
                                    <a @click.prevent="updateProductQuantity(basketProduct.quantity - 1, basketProduct.product.id)" class="p-4">
                                        <MinusIcon class="inline h-4 w-4"/>
                                    </a>
                                    <input @change="updateProductQuantity($event.target.value, basketProduct.product.id)" type="number" :value="basketProduct.quantity" class="inline w-12 h-12 text-center p-0">
                                    <a @click.prevent="updateProductQuantity(basketProduct.quantity + 1, basketProduct.product.id)" class="p-4">
                                        <PlusIcon class="inline h-4 w-4" />
                                    </a>
                                </span>
                            </td>
                            <td class="py-4 text-right">
                                <p class="font-semibold mr-1 text-xl">{{ moneyFormat(formatBasketProductPrice(basketProduct, basketProduct.quantity)) }}</p>
                                <a class="text-sm" @click.prevent="removeProductFromBasket(basketProduct.product.id)">Remove</a>
                            </td>
                        </tr>
                    </table>

                    <div v-if="basketHasProducts" class="border-black border-t pt-16">
                        <div class="ml-auto lg:w-1/3">
                            <p class="flex justify-between text-2xl mb-2"><span>Subtotal</span><span>{{ moneyFormat(basketSubTotal) }}</span></p>
                            <p class="flex justify-between text-2xl mb-2"><span>VAT</span><span>{{ moneyFormat(basketTaxTotal) }}</span></p>
                            <p v-if="basketDiscountTotal" class="flex justify-between text-2xl mb-2"><span>Discount</span><span>-{{ moneyFormat(basketDiscountTotal) }}</span></p>
                            <p class="flex justify-between text-3xl font-semibold"><span>Total</span><span>{{ moneyFormat(basketTotal) }}</span>
                            </p>
                            <p class="text-right mt-4">Delivery calculated at checkout</p>
                        </div>
                    </div>
                    <div class="pb-16">
                        <div class="flex mt-16 flex-col justify-between lg:flex-row" data-aos="fade-in">
                            <LinkButton :href="$router.resolve({name: 'products'}).href" title="Products" text-colour="black" class="mb-2">Continue Shopping</LinkButton>
                            <LinkButton v-if="basketHasProducts" :href="$router.resolve({name: 'checkout', params: { basket_reference: basket.basket_reference}}).href" title="Checkout" background-colour="black" text-colour="white" class="mb-2">Checkout</LinkButton>
                        </div>
                    </div>
                </Container>
            </section>
        </main>
    </DefaultLayout>
</template>

<script>
    import Container from '@/components/Container.vue'
    import LinkButton from '@/components/elements/LinkButton.vue'
    import {PlusIcon, MinusIcon} from '@heroicons/vue/24/outline'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import MoneyFormat from '@/mixins/moneyFormat'
    import BasketActions from '@/mixins/basketActions'
    import ProductContent from '@/mixins/productContent'
    import StoreContent from '@/mixins/storeContent'

    export default {
        name: 'BasketPage',
        inject: ['sdk', 'dataStore'],
        mixins: [StoreContent, MoneyFormat, BasketActions, ProductContent],
        components: {
            TextMasthead,
            Container,
            LinkButton,
            PlusIcon,
            MinusIcon,
            DefaultLayout
        }
    }
</script>
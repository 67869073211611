export default {
    computed: {
        siteTitle: function() {
            return this.dataStore.getSite().title ? this.dataStore.getSite().title : this.dataStore.getStore().name
        },
        pageTitle: function () {
            return this.defaultPageTitle
        },
        defaultPageTitle: function () {
            return this.dataStore.getPage() && this.dataStore.getPage().seo.title ? this.dataStore.getPage().seo.title : this.$route.meta.title
        },
        pageDescription: function () {
            return this.defaultPageDescription
        },
        defaultPageDescription: function () {
            return this.dataStore.getPage() && this.dataStore.getPage().seo.description ? this.dataStore.getPage().seo.description : this.dataStore.getSite().description
        }
    }
}
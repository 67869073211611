<template>
    <section class="bg-transparent">
        <Container class="py-16 lg:max-w-4xl" animation="fade-in">
            <span v-html="content" class="prose prose-black"></span>
        </Container>
    </section>
</template>

<script>
    import Container from '@/components/Container.vue'

    export default {
        name: 'HtmlSection',
        components: {Container},
        props: {
            content: String
        }
    }
</script>
export default {
    methods: {
        getThumbnail: function (product) {
            if (product.thumbnail) {
                return product.thumbnail
            }
            if (product.images.length > 0) {
                return product.images[0]
            }
            if (product.parent_product) {
                if (product.parent_product.thumbnail) {
                    return product.parent_product.thumbnail
                }

                if (product.parent_product.images.length > 0) {
                    return product.parent_product.images[0]
                }
            }

            return null
        },
        getColourImage: function (childProduct) {
            let colourImage = null

            childProduct.attributes.forEach((attribute) => {
                attribute.values.forEach((value) => {
                    if (attribute.name === 'Colour Image') {
                        colourImage = value.value
                    }
                })
            })

            return colourImage
        },
        productCategory: function (product, categorySlug) {
            const categoryOption = product.category_options.filter(categoryOption => {
                if (categoryOption.category.slug === categorySlug) {
                    return categoryOption
                }
            })

            return categoryOption.length > 0 ? categoryOption[0] : null
        },
        productHasInventory: function (product) {
            if (product.track_inventory) {
                return product.inventory > 0
            }

            return true
        },
        colourVariants: function (product) {
            const colourVariants = {}

            product.child_products.forEach(childProduct => {
                childProduct.category_options.forEach(categoryOption => {
                    if (categoryOption.category.slug === 'colour' && this.productHasInventory(childProduct)) {
                        colourVariants[categoryOption.id] = childProduct
                        return
                    }
                })
            })

            return Object.values(colourVariants)
        }
    }
}
<template>
    <Accordion v-for="(item, index) in items" :key="index" :title="item.title"
               :open="'open' in item ? item.open : false" class="py-6" :background-colour="_backgroundColour" :text-colour="_textColour">
        <span v-html="item.content" class="prose" :class="'prose-' + _textColour"></span>
    </Accordion>
</template>

<script>
    import ComponentTheme from '@/mixins/componentTheme'
    import Accordion from '@/components/elements/Accordion.vue'

    export default {
        name: 'AccordionList',
        mixins: [ComponentTheme],
        components: {
            Accordion
        },
        props: {
            items: Array
        }
    }
</script>
<template>
    <!--<section class="bg-black text-white">
        <Container class="py-16 lg:py-24">
            <div class="grid gap-4 items-center lg:gap-8 lg:grid-cols-2">
                <div class="text-center lg:max-w-xl lg:m-auto">
                    <h2>Stay in the loop</h2>
                    <p class="text-lg">Join our email list to stay up-to-date about the latest products and offers, plus get 10% off.</p>
                </div>
                <div class="lg:max-w-xl">
                    <form v-if="signUpSuccess === null" @submit.prevent="signUp($event)" class="flex flex-col lg:flex-row">
                        <input type="text" name="email" class="flex-1 bg-transparent border-white text-white py-3 px-6 mb-2 lg:mb-0 lg:mr-2" placeholder="Email address" required />
                        <Button background-colour="white" text-colour="black">Sign Up</Button>
                    </form>
                    <p v-else-if="signUpSuccess" class="mb-0 text-center text-lg lg:text-2xl">
                        Thank you for signing up.
                    </p>
                    <p v-else-if="!signUpSuccess" class="mb-0 text-center text-lg lg:text-2xl">
                        Sorry, we couldn't add you to our mailing list. Please try again later.
                    </p>
                </div>
            </div>
        </Container>
    </section>-->
    <footer class="bg-secondary">
        <div>
            <Container class="py-16 lg:py-24">
                <div class="grid grid-cols-12 gap-4 lg:gap-8">
                    <template v-if="footerContent">
                        <div v-for="(panel, index) in footerContent.value.panels" :key="index" class="mb-4 col-span-6 lg:col-span-3 text-black">
                            <p class="h6 uppercase font-semibold">{{ panel.title }}</p>
                            <ul v-if="panel.title" class="lg:text-md">
                                <li v-for="(link, index) in panel.list" :key="index" class="mb-2">
                                    <a :href="link.link_url" :title="link.title">{{ link.title }}</a>
                                </li>
                            </ul>
                            <p v-if="panel.text" class="whitespace-pre-line">{{ panel.text }}</p>
                        </div>
                    </template>
                    <div class="mb-4 col-span-12 lg:col-span-3 text-white">
                        <ul class="flex items-center lg:justify-end gap-1">
                            <li>
                                <img src="/icons/visa.svg" class="h-11 w-auto mr-1" alt="Image: Visa" />
                            </li>
                            <li>
                                <img src="/icons/mastercard.svg" class="h-11 w-auto mr-1" alt="Image: Mastercard" />
                            </li>
                            <li>
                                <img src="/icons/apple-pay.svg" class="h-11 w-auto mr-1" alt="Image: Apple Pay" />
                            </li>
                            <li>
                                <img src="/icons/google-pay.svg" class="h-11 w-auto mr-1" alt="Image: Google Pay" />
                            </li>
                            <li>
                                <img src="/icons/paypal.svg" class="h-11 w-auto" alt="Image: Paypal" />
                            </li>
                        </ul>
                    </div>
                    <div class="col-span-12">
                        <p class="text-sm mb-0 text-small">&copy; T-ShirtsDirect {{ (new Date()).getFullYear()}}</p>
                    </div>
                </div>
            </Container>
        </div>
    </footer>
</template>

<script>
    import Container from '@/components/Container.vue'
    import StoreContent from '@/mixins/storeContent'
    import SiteContent from '@/mixins/siteContent'
    import Button from '@/components/elements/Button.vue'

    export default {
        name: 'Footer',
        inject: ['dataStore', 'sdk'],
        mixins: [StoreContent, SiteContent],
        components: {Container, Button},
        data() {
            return {
                signUpSuccess: null
            }
        },
        computed: {
            footerContent: function () {
                return this.getSiteContentByKey('footer')
            }
        },
        methods: {
            signUp: function (event) {
                this.sdk.createCustomer({
                    email : event.target.email.value,
                    allows_marketing: true,
                })
                    .then(response => {
                        this.signUpSuccess = true
                    }).catch(error => {
                    this.signUpSuccess = false
                    this.errorHandler.handleError(error);
                })
            },
        },
    }
</script>